<template>
  <DetailTemplate
    :customClass="'project-detail detail-page'"
    v-if="getPermission('project:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
            {{ getPageTitle }}
          </h1>
          <!-- <CustomStatus
            :status="projectArr.status"
            endpoint="project/status"
          ></CustomStatus> -->
          <v-chip
            :color="getColor(projectArr.status)"
            label
            v-if="projectArr.status"
            class="ml-2"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(projectArr.status) }}
            </span>
          </v-chip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('project:update')">
        <template v-if="projectArr.status < 4">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="pageLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateProject()"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Project</span>
          </v-tooltip>
        </template>
        <v-btn
          :disabled="pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('opportunity.create', {
              query: {
                project: $route.params?.id,
              },
            })
          "
        >
          <v-icon class="me-2" right>mdi-plus</v-icon> Opportunity
        </v-btn>
        <v-menu
          v-if="false"
          style="z-index: 99 !important"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="me-2" right>mdi-plus</v-icon> Add
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectQuoteCosting">
              <v-list-item
                link
                v-on:click="createMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-menu
          v-if="projectArr.status < 4"
          style="z-index: 99 !important"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="projectTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: 84vh; position: relative"
      >
        <v-tabs-items v-model="projectTab">
          <v-tab-item v-if="getPermission('project:view')" :value="'project'">
            <OverviewCreate
              :projectArr="projectArr"
              :project="project"
              v-on:reload:project="getProject"
            ></OverviewCreate>
          </v-tab-item>

          <v-tab-item
            v-if="getPermission('attachment:view')"
            value="attachment"
          >
            <Attachments
              :images="projectArr.documents"
              :parent="projectArr && projectArr.id ? projectArr.id : 0"
              type="project"
              :isPageLoading="pageLoading"
            ></Attachments>
          </v-tab-item>
          <v-tab-item value="opportunity">
            <Opportunity
              :type-id="projectArr?.id"
              :detail="projectArr"
              type="project"
            ></Opportunity>
          </v-tab-item>
          <v-tab-item :value="'visit'" v-if="false">
            <VisitListingTemplate
              is-admin
              visit-type="all"
              :visit-status="0"
              detailRoute="visit.detail"
              internal
              :project-id="projectArr && projectArr.id ? projectArr.id : 0"
              :customer-id="
                projectArr && projectArr.customer && projectArr.customer.id
                  ? projectArr.customer.id
                  : 0
              "
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="task">
            <Tasks :detail="projectArr" type="project"></Tasks>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="timesheet">
            <Timesheets :detail="projectArr" type="project"></Timesheets>
          </v-tab-item>
          <v-tab-item value="contract" v-if="getPermission('contract:view')">
            <Contracts :detail="projectArr" type="project"></Contracts>
          </v-tab-item>
          <v-tab-item :value="'costing'">
            <ProjectCostings
              :type-id="projectArr?.id"
              :detail="projectArr"
              type="project"
            ></ProjectCostings>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <Tickets :detail="projectArr" type="project"></Tickets>
          </v-tab-item>
          <v-tab-item v-if="getPermission('quotation:view')" value="quotation">
            <Quotations :detail="projectArr" type="project"></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('quotation:view')" value="orders">
            <Orders :detail="projectArr" type="project"></Orders>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('purchase-order:view')"
            value="purchase-order"
          >
            <PurchaseOrders
              :detail="projectArr"
              type="project"
            ></PurchaseOrders>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail="projectArr" type="project"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="project"
              :type_id="projectArr && projectArr.id ? projectArr.id : 0"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import OverviewCreate from "@/view/pages/project/partials/Overview-Create";
import Tasks from "@/view/pages/partials/Detail/Tasks";
// import Milestones from "@/view/pages/partials/Detail/Milestones";
import Opportunity from "@/view/pages/partials/Detail/Opportunity";
import Attachments from "@/view/pages/partials/Detail/Attachments";
import Timesheets from "@/view/pages/partials/Detail/Timesheets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Orders from "@/view/pages/partials/Detail/Orders";
import ProjectCostings from "@/view/pages/partials/Detail/ProjectCosting";
import PurchaseOrders from "@/view/pages/partials/Detail/PurchaseOrder";
import Contracts from "@/view/pages/partials/Detail/Contract";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";

/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-detail",
  title: "Project",
  data() {
    return {
      project: 0,
      pageLoading: true,
      projectArr: {
        id: 1,
        barcode: "PR000001",
        reference: "dgeg",
        soled_job_no: null,
        attention: null,
        name: "Project name",
        description: "hrthr",
        customer: {
          id: 5,
          barcode: "C-000008",
          display_name: "BThurst Tech Soft",
          company_name: "BThurst Tech Soft",
          profile_logo: {
            id: 77,
            type: "1",
            primary: false,
            super_parent: "0",
            batch: null,
            super_type: "0",
            file: {
              url: "https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/jpeg/ugX9Q1vqw4tpDFdRBEgqjF0FujdEpFeOvQQ62ctg.jpg",
              name: "sample.jpg",
              path: "SL012021VF/files/image/jpeg/ugX9Q1vqw4tpDFdRBEgqjF0FujdEpFeOvQQ62ctg.jpg",
            },
            name: "sample.jpg",
            added_by: "1",
            added_at: "2024-08-29 14:35:48",
            updated_by: "0",
            updated_at: "2024-08-29 14:35:49",
            start_date: null,
            end_date: null,
            reminder_date: null,
            size: "46748",
            extension: null,
            file_type: "logo",
            description: null,
            type_in_module: null,
            module_type_parent: null,
            module_type_parent_id: "0",
            dimensions: "300 x 236",
            tags: null,
          },
        },
        lead: "0",
        proposal: "0",
        quotation: "0",
        contact: "0",
        cost: "0.00",
        rate_per_hour: "0.00",
        estimated_hour: "0.00",
        total_expenses: "0.00",
        total_billable_expenses: "0.00",
        total_billed_expenses: "0.00",
        total_unbilled_expenses: "0.00",
        total_logged_hours: "0.00",
        billing_type: "1",
        started_at: "2024-08-23",
        deadline: "2024-09-23",
        finished_at: null,
        progress: "0",
        status: 1,
        added_by: "1",
        added_at: "2024-08-23 13:19:25",
        updated_by: "1",
        updated_at: "2024-09-03 10:58:35",
        customer_purchase_order: "bhwf",
        project_value: "100000",
        project_budget: "60",
        specification: null,
        bank_name: null,
        bank_amount: "0",
        bank_remark: null,
        insurance_company: null,
        insurance_amount: "1000000",
        insurance_remark: null,
        property: {
          id: 16,
          barcode: "P-000023",
          property_name: "",
          customer: 5,
          supplier: "0",
          contact_person: 7,
          is_same: "0",
          is_tenant: 0,
          unit_no: "",
          street_1: "78 AIRPORT BOULEVARD",
          street_2: "JEWEL CHANGI AIRPORT",
          zip_code: "819666",
          country: "singapore",
          zone_territory: null,
          state: "0",
          city: "0",
          latitude: "1.3603410066102",
          longitude: "103.98907755021",
          remarks: null,
          entity: "Business Thrust Pte Ltd.",
          entity_id: "1",
          type: 1,
          activated: true,
          added_by: "1",
          added_at: "2024-08-07 11:01:20",
          updated_by: "0",
          updated_at: null,
          property_address:
            "78 AIRPORT BOULEVARD JEWEL CHANGI AIRPORT singapore 819666",
        },
        billing: null,
        property_contact_person: "7",
        billing_contact_person: "7",
        project_balance: 10000,
        customer_billing: null,
        total_tasks: 0,
        open_tasks: 0,
        task_percentage: 100,
        total_days: 31,
        days_left: 18,
        days_percentage: 41.94,
        created_at: "1 week ago",
        modified_at: "1 day ago",
        billing_type_text: "Fixed Rate",
        status_text: "Not Started",
        more_actions: [
          {
            title: "Mark as In-Progress",
            action: "mark_as_inprogress",
            icon: "mdi-check-all",
            disabled: false,
          },
          {
            title: "Mark as Hold",
            action: "mark_as_hold",
            icon: "mdi-check-all",
            disabled: false,
          },
          {
            title: "Mark as Cancelled",
            action: "mark_as_cancel",
            icon: "mdi-check-all",
            disabled: false,
          },
          {
            title: "Mark as Finished",
            action: "mark_as_finish",
            icon: "mdi-check-all",
            disabled: false,
          },
        ],
        members: [],
        managers: [],
        property_person: {},
        billing_person: {},
        documents: [
          // {
          //   id: 92,
          //   type: "111",
          //   primary: false,
          //   super_parent: "0",
          //   batch: null,
          //   super_type: "0",
          //   file: {
          //     url: "https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/B9xpVBZJ6u6wTVNE5SQwY8gQNL1DLUj9WAyBcmkM.png",
          //     name: "discount.png",
          //     path: "SL012021VF/files/image/png/B9xpVBZJ6u6wTVNE5SQwY8gQNL1DLUj9WAyBcmkM.png",
          //   },
          //   name: "discount.png",
          //   added_by: {
          //     id: 1,
          //     display_name: "Admin",
          //     user_email: "admin@bthrust.com",
          //     first_name: "Super",
          //     last_name: "Admin",
          //     full_name: "Super Admin",
          //     profile_logo: null,
          //   },
          //   added_at: "2024-09-03 10:58:26",
          //   updated_by: "0",
          //   updated_at: "2024-09-03 10:58:35",
          //   start_date: null,
          //   end_date: null,
          //   reminder_date: null,
          //   size: "15784",
          //   extension: null,
          //   file_type: null,
          //   description: null,
          //   type_in_module: null,
          //   module_type_parent: null,
          //   module_type_parent_id: "0",
          //   dimensions: "324 x 189",
          //   tags: null,
          //   newextention: "png",
          // },
        ],
      },
      fileArr: [],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "project",
          disabled: false,
        },
        {
          title: "Opportunity",
          icon: "mdi-phone",
          key: "opportunity",
          disabled: false,
        },
        //{
        //title: "Visit",
        //icon: "mdi-credit-card",
        // key: "visit",
        // disabled: false,
        //},

        {
          title: "Project Costings",
          icon: "mdi-credit-card",
          key: "costing",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Orders",
          icon: "mdi-account-multiple",
          key: "orders",
          disabled: false,
        },
        {
          title: "Attachments",
          icon: "mdi-credit-card",
          key: "attachment",
          disabled: false,
        },
        /* {
          title: "Tasks",
          icon: "mdi-credit-card",
          key: "task",
          disabled: false,
        },
        {
          title: "Timesheets",
          icon: "mdi-credit-card",
          key: "timesheet",
          disabled: false,
        },
        
         {
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          disabled: false,
        } ,
        
        {
          title: "Project Costings",
          icon: "mdi-credit-card",
          key: "costing",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Purchase Orders",
          icon: "mdi-account-multiple",
          key: "purchase-order",
          disabled: false,
        },
        {
          title: "Contracts",
          icon: "mdi-account-multiple",
          key: "contract",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        }, */
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      projectQuoteCosting: [
        {
          title: "Create Costing",
          value: "costing",
          icon: "mdi-credit-card",
        },
        {
          title: "Create Quotation",
          value: "quotation",
          icon: "mdi-account-multiple",
        },
      ],
      projectMoreAction: [
        {
          title: "Mark as In-Progress",
          action: "mark_as_inprogress",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as Hold",
          action: "mark_as_hold",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as Cancelled",
          action: "mark_as_cancel",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as Finished",
          action: "mark_as_finish",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
    };
  },
  components: {
    //Milestones,
    Opportunity,
    Tasks,
    Attachments,
    Tickets,
    Contracts,
    Invoices,
    Quotations,
    Orders,
    PurchaseOrders,
    Timesheets,
    DetailTemplate,
    OverviewCreate,
    /*  CustomStatus, */
    ProjectCostings,
    InternalHistoryDetail,
    VisitListingTemplate,
  },
  methods: {
    updateProject() {
      let _this = this;
      _this.$router.push(
        _this.getDefaultRoute("project.update", {
          params: {
            id: _this.projectArr && _this.projectArr ? _this.projectArr.id : 0,
          },
          // query: {
          //   customer: _this.lodash.toSafeInteger(_this.projectArr.customer.id),
          //   property: _this.lodash.toSafeInteger(_this.projectArr.property.id),
          // },
        })
      );
    },
    refressData() {
      this.getProject();
      this.getFile();
    },
    getStatus(status) {
      if (status == 1) {
        return "Not Started";
      } else if (status == 2) {
        return "In Progress";
      } else if (status == 3) {
        return "On Hold";
      } else if (status == 4) {
        return "Cancelled";
      } else if (status == 5) {
        return "Finished";
      }
    },
    getColor(status) {
      if (status == 1) {
        return "blue white--text";
      } else if (status == 2) {
        return "purple white--text";
      } else if (status == 3) {
        return "orange white--text";
      } else if (status == 4) {
        return "red white--text";
      } else if (status == 5) {
        return "green white--text";
      }
    },
    createMoreAction(action) {
      const _this = this;
      switch (action) {
        case "costing":
          // _this.updateProjectStatus(2);
          break;
        case "quotation":
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              // params: {
              //   id: _this.projectArr.id,
              // },
            })
          );
          break;
      }
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("project.create", {
              query: {
                duplicate: _this.projectArr.id,
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.id
                ),
                property: _this.lodash.toSafeInteger(
                  _this.projectArr.property.id
                ),
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("project.update", {
              params: {
                id: _this.projectArr.id,
              },
              query: {
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.id
                ),
                property: _this.lodash.toSafeInteger(
                  _this.projectArr.property.id
                ),
              },
            })
          );
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "download_pdf":
          break;
        case "print":
          break;
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "project/" + _this.project + "/status",
          data: { status },
        })
        .then(() => {
          _this.getProject();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProject() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + _this.project,
        })
        .then(({ data }) => {
          _this.projectArr = data;
          _this.projectMoreAction = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getFile() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project-file/" + _this.project,
        })
        .then(({ data }) => {
          _this.fileArr = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProject();
    if (this.project > 0) {
      /* _this.getFile(); */
    }
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Project",
        route: "project",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.project = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.project || _this.project <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.projectTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.projectArr) === false) {
        if (this.projectArr.name) {
          result.push(this.projectArr.name);
        }
        if (this.projectArr.barcode) {
          result.push(this.projectArr.barcode);
        }
      }
      return result.join(" • ");
    },
    projectTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.projectTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "project";
      },
    },
  },
};
</script>
